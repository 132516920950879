
import { defineComponent, inject } from 'vue'
import { debounce } from 'debounce'
import { DEBOUNCE_INTERVAL } from '@/config'

import { Koi } from '@/data/koi/types'
import { KoiRepositoryKey } from '@/data/injectables'

import { useToast } from '@/util/toast'
import { useErrorHandler } from '@/util/error-handler'

import KoiListItem from '@/views/koi/KoiListItem.vue'
import SearchNavBar from '@/components/navbar/SearchNavBar.vue'
import ActionBar from '@/components/ActionBar.vue'
import ActionButton from '@/components/ActionButton.vue'
import ListWrapper from '@/components/ListWrapper.vue'

export default defineComponent({
  components: {
    KoiListItem,
    SearchNavBar,
    ActionBar,
    ActionButton,
    ListWrapper,
  },
  setup: () => {
    const koiRepository = inject(KoiRepositoryKey)
    const toast = useToast()
    const { handleError } = useErrorHandler(toast)
    return { koiRepository, handleError }
  },
  data() {
    return {
      loading: false,
      error: false,
      searchText: '',
      kois: [] as Koi[],
    }
  },
  watch: {
    searchText() {
      this.loading = true
      this.loadKoisDebounced()
    },
  },
  created() {
    this.loadKois()
  },
  methods: {
    handleViewKois(): void {
      window.open(
        'https://nishikigois.aguasdaolaria.com.br/?showBasePrice=true',
        '_blank'
      )
    },
    handleEdit(koiId: string): void {
      this.$router.push({ path: `/kois/${koiId}/edit` })
    },
    handleCreate(): void {
      this.$router.push({ path: '/kois/create' })
    },
    async loadKois(): Promise<void> {
      try {
        this.error = false
        this.loading = true

        if (this.searchText) {
          const koi = await this.koiRepository?.getKoi(Number(this.searchText))
          this.kois = koi ? [koi] : []
        } else {
          const res = await this.koiRepository?.getKois()
          this.kois = res?.kois || []
        }
      } catch (e) {
        this.error = true
        this.handleError(e)
      } finally {
        this.loading = false
      }
    },
    loadKoisDebounced: debounce(function (this: { loadKois: () => void }) {
      this.loadKois()
    }, DEBOUNCE_INTERVAL),
  },
})
