
import { Koi } from '@/data/koi/types'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    koi: {
      type: Object as PropType<Koi>,
      required: true,
    },
  },
  computed: {
    statusStyle(): string {
      return `tag--${this.koi.status.toLowerCase()}`
    },
  },
  methods: {
    formatCurrency(value: string): string {
      return Number(value).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })
    },
    getThumbnailUrl(url: string): string {
      let match
      if (url.indexOf('youtu.be') >= 0) {
        match = url.match(/youtu.be\/([\w-]+)$/)
      } else if (url.indexOf('youtube.com/shorts') >= 0) {
        match = url.match(/youtube.com\/shorts\/([\w-]+)$/)
      } else if (url.indexOf('youtube.com/watch?v=') >= 0) {
        match = url.match(/youtube.com\/watch\?v=([\w-]+)$/)
      } else {
        match = null
      }

      const hash = match ? match[1] : null

      return hash ? `https://i.ytimg.com/vi/${hash}/hqdefault.jpg` : ''
    },
  },
})
